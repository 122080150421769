import { create, test,  enforce, only} from "vest";

export default create((data, currentField) => {
    only(currentField, data);
  

// ///Client Validators //////

    test("name", "Este campo não pode ser vazio", () => {
        enforce(data.name).isNotEmpty();
    });
    
    test("name", "Nome deve ter no mínimo 2 caracteres", () => {
        enforce(data.name).longerThanOrEquals(2);
   
    });

    
    test("motherName", "Nome deve ter no mínimo 2 caracteres", () => {
        enforce(data.motherName).longerThanOrEquals(2);
   
    });


    test("email", "Este é não pode ser vazio", () => {
        enforce(data.email).isNotEmpty();
    });


    test("email", "Este campo deve ser um email válido", () => {
        
        enforce(data.email).matches(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i);
    });

    test("phone1", "Este campo não pode ser vazio", () => {
        enforce(data.phone1).isNotEmpty();
    });
    test("phone1", "Este campo deve ter no minimo 11 caracteres", () => {
        enforce(data.phone1).longerThanOrEquals(11);
    });
    test("phone1", "Número de telefone inválido", () => {
        enforce(data.phone1).matches(/^\(?\d{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/);
});

    

    test ("cpf", "Este campo não pode ser vazio", () => {
        enforce(data.cpf).isNotEmpty();
    });

    test ("cpf", "Este campo deve ter no minimo 11 caracteres", () => {
        enforce(data.cpf).longerThanOrEquals(11);
    });
   }
);
   /////End Client Validators////////


